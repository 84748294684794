<template>
    <b-row>
        <b-col>
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12" class="text-left mb-2">
                            <b-badge :variant="statusVariant(this.status)">
                                <span v-if="this.status === 1">Waiting Approval</span>
                                <span v-else-if="this.status === 2">Approved</span>
                                <span v-else>Rejected</span>
                            </b-badge>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Employee Name"
                            label-for="name"
                            >
                                <b-form-input
                                    id="name"
                                    placeholder="Employee Name"
                                    v-model="form.name"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <b-form-group
                            label="Start Date"
                            label-for="start_date"
                            >
                                <flat-pickr
                                id="start_date"
                                v-model="form.start_date"
                                class="form-control"
                                :disabled="this.status !== 1? true : false"
                                required
                                />
                                <!-- <p>Value: '{{ form.start_date }}'</p> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="End Date"
                            label-for="end_date"
                            >
                                <flat-pickr
                                id="end_date"
                                v-model="form.end_date"
                                class="form-control"
                                :disabled="this.status !== 1? true : false"
                                required
                                />
                                <!-- <p>Value: '{{ form.end_date }}'</p> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <label for="duration" class="align-top">Duration</label>
                            <b-input-group
                            append="Days"
                            >
                                <b-form-input
                                    id="duration"
                                    placeholder="Duration"
                                    v-model="form.duration"
                                    disabled
                                />
                            </b-input-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Location"
                            label-for="location"
                            >
                            <div>
                                <b-form-textarea
                                    id="location"
                                    placeholder="Location"
                                    v-model="form.location"
                                    :disabled="this.status !== 1? true : false"
                                    required
                                />

                            </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Description"
                            label-for="description"
                            >
                                <b-form-textarea
                                    id="description"
                                    placeholder="Description"
                                    v-model="form.description"
                                    :disabled="this.status !== 1? true : false"
                                    required
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="4" v-if="this.status === 3">
                            <b-form-group
                            label="Reject Reason"
                            label-for="reject_reason"
                            >
                                <b-form-textarea
                                    id="reject_reason"
                                    placeholder="Reject Reason"
                                    v-model="form.reject_reason"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2" v-if="status === 1">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updatePermit()"
                            block
                            >
                            Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
        <b-col cols="12" md="12" class="mt-0" v-if="status === 1">
            <b-row>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="danger"
                    class="mr-1"
                    block
                    v-b-modal.modal-reject
                    >
                    Reject
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="success"
                    class="mr-1"
                    block
                    @click="approve()"
                    >
                    Approve
                    </b-button>
                </b-col>
            </b-row>
        </b-col>

        <!-- modal change password -->
        <b-modal
        id="modal-reject"
        title="Reject Form"
        ok-title="Reject"
        cancel-variant="outline-secondary"
        hide-footer
        >
            <validation-observer ref="loginValidation">
            <b-form ref="form" @submit.prevent>
                <b-form-group
                :state="errors"
                label="Reject Reason"
                label-for="reject"
                invalid-feedback="Reject Reason required"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="reject"
                    rules="required"
                    >
                        <b-form-input
                            id="reject"
                            name="reject"
                            type="text"
                            v-model="form.reject_reason"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Reject Reason"
                            required="required"
                        />
                        <small class="text-danger">{{ errors[0] || errMessage }}</small>
                    </validation-provider>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-button
                            class="mt-3"
                            type="submit"
                            variant="danger"
                            block
                            @click="reject()"
                        >
                            Reject
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            </validation-observer>
        </b-modal>
        <!-- end modal change password -->
    </b-row>
</template>

<script>
import {
    BInputGroup, BFormTextarea, BBadge, BImg, BFormTimepicker, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    LMap, LTileLayer, LMarker, LPopup
} from 'vue2-leaflet'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        ToastificationContent,
        flatPickr,
        BInputGroup,
        BFormTextarea,
        BBadge,
        ValidationProvider,
        ValidationObserver,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        BImg,
        BFormTimepicker,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                name: '',
                start_date: null,
                end_date: null,
                duration: null,
                description: '',
                location: '',
                reject_reason: ''
            },
            status: '',
            errors: '',
            errMessage: ''
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-warning',
                2 : 'light-success',
                3 : 'light-danger'
            }

            return status => statusColor[status]
        },
    },
    created() {
        const id = this.$route.params.id
        // console.log(id);
        this.$http
            .get('permits/' +id)
            .then((response) => {
                // console.log(response.data.data)
                this.status = response.data.data.status
                this.form.name = response.data.data.user_name
                this.form.start_date = response.data.data.start_date
                this.form.end_date = response.data.data.end_date
                this.form.duration = response.data.data.duration
                this.form.description = response.data.data.description
                this.form.location = response.data.data.location
                this.form.reject_reason = response.data.data.reject_reason
            }).catch((errors) => {
                console.log(errors.response)
            });
    },
    methods: {
        updatePermit() {
            const id = this.$route.params.id
            this.$http
            .post('permits/'+id+'/update', {
                'name': this.form.name,
                'start_date': this.form.start_date,
                'end_date': this.form.end_date,
                'description': this.form.description,
                'location': this.form.location
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Permit',
                        variant: 'success',
                    },
                })
                location.href = "/permits"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        approve() {
            const id = this.$route.params.id
            this.$http
            .post('permits/'+id+'/approve')
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Permit Approved',
                        variant: 'success',
                    },
                })
                location.href = "/permits"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        reject() {
            const id = this.$route.params.id
            this.$http
            .post('permits/'+id+'/reject', {
                'reject_reason': this.form.reject_reason
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Permit Rejected',
                        variant: 'danger',
                    },
                })
                location.href = "/permits"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>